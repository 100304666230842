import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import history from "../../../history";

import LinkIcon from "../../../images/icons/svg/external-link-icon.svg";
import InfoIcon from "../../../images/icons/svg/info-tooltip-active.svg";
import ArrowUpIcon from "../../../images/icons/svg/sorting-arrow-up.svg";
import ArrowDownIcon from "../../../images/icons/svg/sorting-arrow-down.svg";
import accountSetting from "../../../services/AccountSetting";

import styles from "./TableView.module.scss";

export default (props) => {
  const {
    label,
    items,
    itemFields,
    sort,
    sortingColumnIndex,
    sortColumn,
    sortOrder,
    itemClickFunction,
  } = props;

  const renderEmptyState = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.emptyStateContainer}>
          <div className={styles.emptyMessageContainer}>
            <img className={styles.InfoIcon} src={InfoIcon} />
            No content found
          </div>
        </div>
      </div>
    );
  };

  const renderItem = (item, itemField, ifIndex) => {
    if (item.content_type === "image" && itemField === "image") {
      return (
        <td key={`table-item-attr-${ifIndex}`}>
          <img
            className={styles.image}
            src={`${process.env.REACT_APP_API_URL}/${item.image_url}`}
          />
        </td>
      );
    } else {
      return (
        <td key={`table-item-attr-${ifIndex}`}>{item[itemField] || "-"}</td>
      );
    }
  };

  const renderTableView = () => {
    return (
      <div className={styles.tableViewContainer}>
        <div className={styles.subContainer}>
          <div className={styles.tableViewHeader}>{label}</div>
          <table className={styles.titleTableContainer}>
            <colgroup>
              {itemFields.map((column, index) =>
                index === 0 ? (
                  <col key={index} span="1" className={styles.colLarge} />
                ) : (
                  <col key={index} span="1" className={styles.col} />
                )
              )}
            </colgroup>
            <thead className={styles.header}>
              <tr>
                {itemFields.map((itemField, index) => (
                  <th className={styles.columnName}>
                    <div className={styles.container}>
                      <div
                        className={`${styles.text} ${
                          sortColumn === "title" && styles.textBold
                        }`}
                      >
                        {itemField}
                      </div>
                      <div className={styles.icon}>
                        <img
                          className={
                            sortColumn === "title" && sortOrder === "desc"
                              ? styles.show
                              : ""
                          }
                          src={ArrowDownIcon}
                        />
                        <img
                          className={`${
                            sortColumn === "title" ? styles.arrowUpShow : ""
                          } ${
                            sortColumn === "title" && sortOrder === "asc"
                              ? styles.show
                              : ""
                          }`}
                          src={ArrowUpIcon}
                        />
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={styles.body}>
              {items.map((item, index) => (
                <tr
                  onClick={() => {
                    itemClickFunction(item);
                  }}
                  key={`table-item-${item.id}`}
                  name={item.id}
                >
                  {itemFields.map((itemField, ifIndex) =>
                    renderItem(item, itemField, ifIndex)
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {items.length > 0 ? renderTableView() : renderEmptyState()}
    </div>
  );
};
